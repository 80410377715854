.af-view .switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 20px;
  margin-bottom: 0px !important;
}
/* Hide default HTML checkbox */
.af-view .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.af-view .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.af-view .slider:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 0px;
  bottom: -2px;
  background-color: #000000;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
.af-view input:not(:checked) + .slider img.right-img {
  display: none;
}
.af-view input:checked + .slider img.left-img {
  display: none;
}
.af-view input:checked + .slider:before {
  background-color: #2467eb;
}
.af-view .slider img {
  filter: brightness(0) invert(1) !important;
  width: 16px;
  height: 21px;
  position: absolute;
}
.af-view .slider img.left-img {
  left: 3px;
  bottom: 0px;
}
.af-view .slider img.right-img {
  right: 0px;
  bottom: -1px;
  width: 16px;
}
.af-view input:checked + .slider {
  background-color: rgba(36, 103, 235, 0.6);
}
.af-view input:focus + .slider {
  box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
}
.af-view input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.af-view .slider.round {
  border-radius: 15px;
}
.af-view .slider.round:before {
  border-radius: 50%;
}
.af-view .input-checked-label {
  color: rgb(36, 103, 235) !important;
}

.user-menu-dropdown-block.black-bg-dark-mode.all-whiten-in-dark-mode.whiten-border-in-dark-mode.help-user-menu-dropdown-block {
  left: unset !important;
}

.logo-title {
  margin-left: 10px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px;
  overflow: hidden;
}

.body-right-side.light-grey-bg-dark-mode.no-padding {
  height: calc(100% - 71px);
}

@media screen and (max-width: 480px) {
  .user-menu-dropdown-block.black-bg-dark-mode.all-whiten-in-dark-mode.whiten-border-in-dark-mode.help-user-menu-dropdown-block {
    left: unset !important;
  }
}

@media screen and (max-width: 479px) {
  .right-hand-side {
    grid-column-gap: 10px;
  }
}

button,
input:where([type='button'], [type='reset'], [type='submit']),
::file-selector-button {
  appearance: button;
}

button,
input,
select,
optgroup,
textarea,
::file-selector-button {
  font: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  letter-spacing: inherit;
  color: inherit;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
}

::file-selector-button {
  margin-inline-end: 4px;
}

.modal.pricing-iframe-box .modal-body{
    padding: unset!important;
    position: relative;
}
.modal.pricing-iframe-box .modal-container{
    max-width: unset!important;
    padding:unset!important;
}
.modal.pricing-iframe-box .external-pricing-iframe-box{
    height: 800px !important;
    width: 100% !important;
}
@keyframes logoblink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: logoblink 1s infinite;
}

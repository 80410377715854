/* custom-quill.css */
.ql-toolbar {
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 20px;
}

.ql-container.ql-snow {
  border: none;
}

.ql-container .ql-editor h1 {
  margin-top: 16px;
  margin-bottom: 11px;
  font-size: 19px; /* reduced from 32px */
  font-weight: 700;
  line-height: 31px; /* reduced from 36px */
}

.ql-container .ql-editor h2 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px; /* reduced from 32px */
  font-weight: 700;
  line-height: 29px; /* reduced from 36px */
}

.ql-editor > h3 {
  margin-top: 18px; /* reduced from 20px */
  margin-bottom: 9px; /* reduced from 10px */
  font-size: 17px; /* reduced from 24px */
  font-weight: bold;
  line-height: 27px; /* reduced from 30px */
}

.ql-editor > h4 {
  margin-top: 0;
  margin-bottom: 9px; /* reduced from 10px */
  font-size: 15.5px; /* reduced from 18px */
  font-weight: 700;
  line-height: 21px; /* reduced from 24px */
}

.ql-editor > p {
  margin-bottom: 10px;
  font-size: 15.5px; /* reduced from 16px */
  line-height: 24px; /* reduced from 27px */
}

.ql-editor > ul {
  margin-top: 0;
  margin-bottom: 9px; /* reduced from 10px */
  padding-left: 35px; /* reduced from 40px */
}

.ql-editor > li {
  font-size: 15.5px; /* reduced from 16px */
  line-height: 24px; /* reduced from 27px */
}

.ql-editor > a {
  text-decoration: none;
}

.ql-editor > strong {
  font-weight: bold;
}

.tab-link-tab-1-3 {
  background-color: unset !important;
}
.w-tab-link {
  padding: 0 !important;
}
.instructor.v-flexed-instructor {
  width: 400px;
  padding: 16px 10px 0;
  margin-bottom: 0;
  border: 0;
  position: fixed;
  top: 50px;
}

.tabs-menu-2.w-tab-menu {
  margin-top: 8px;
  padding-bottom: 0px;
  justify-content: space-between;
  width: unset;
}

.tabs-content-2 {
  padding-top: 0px !important;
}

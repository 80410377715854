.new-chat-advance-container {
  grid-column-gap: 5px !important;
  grid-row-gap: 5px !important;
  background-color: var(--accent) !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
  padding: 5px !important;
}

.new-chat-advance-text {
  color: var(--primary) !important;
  margin-right: 10px !important;
  font-size: 12px !important;
}

.new-chat-advance-image {
  color: var(--primary) !important;
  font-family:
    Material Icons Outlined,
    sans-serif !important;
  font-size: 20px !important;
}

.message-bubble-2 {
  padding: 0 !important;
  margin: 0 0 15px 0 !important;
}

.w-layout-hflex.flex-block-69 {
  grid-column-gap: 0;
  grid-row-gap: 0;
}

a.button-13 {
  color: #5b92ff;
  text-align: left;
  background-color: #c9d8f8;
  border-radius: 3px;
  height: 30px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
}
